import Phaser from 'phaser';
import { ScrollablePanel } from 'phaser3-rex-plugins/templates/ui/ui-components.js';

import Popup from './Popup';
import Button from '../button/Button';
import TextButton from '../button/TextButton';
import configs from '../../configs/configs';
import { colors, fontFamilies, fontSizes } from '../../../../utils/styles';
import { customFormat, formatter } from '../../../../utils/numbers';
import { getOrdinalSuffix, formatUsername } from '../../../../utils/strings';

const { width, height } = configs;
const rowHeight = 84;
const largeBlackExtraBold = { fontSize: fontSizes.large, color: colors.black, fontFamily: fontFamilies.extraBold };
const largeBlackBold = { fontSize: fontSizes.large, color: colors.black, fontFamily: fontFamilies.bold };
const mediumBlackBoldRight = {
  fontSize: fontSizes.medium,
  color: colors.black,
  fontFamily: fontFamilies.bold,
  align: 'right',
};
const smallBrownExtraBold = { fontSize: fontSizes.small, color: colors.brown, fontFamily: fontFamilies.extraBold };
const smallBrownBold = { fontSize: fontSizes.small, color: colors.brown, fontFamily: fontFamilies.bold };
const smallBlackBold = { fontSize: fontSizes.small, color: colors.black, fontFamily: fontFamilies.bold };

const paginationBtnSize = 66;
const paginationBtnGap = 15;

class PopupLeaderboard extends Popup {
  isEnded = false;
  started = false;
  isUserActive = false;
  numberOfRows = 0;
  items = [];
  avatars = {};
  pages = { reputation: 0, raidPoints: 0, uPoints: 0 };
  limit = 50;
  totalPages = { reputation: 1, raidPoints: 1, uPoints: 1 };
  paginations = { reputation: [], raidPoints: [], uPoints: [] };
  leaderboardUsers = { reputation: [], raidPoints: [], uPoints: [] };
  userRecord = { reputation: null, raidPoints: null, uPoints: null };
  tokenPrice = 0;
  prizePoolEth = 0;
  prizePoolToken = 0;

  constructor(scene, { isSimulator, ...configs } = {}) {
    super(scene, 'popup-extra-large', { title: 'Season Leaderboard', noCloseBtn: true, ...configs });

    this.scene = scene;
    this.isSimulator = isSimulator;
    const events = {
      updateSeason: isSimulator ? 'simulator-update-season' : 'update-season',
      updateLeaderboard: isSimulator ? 'simulator-update-leaderboard' : 'update-leaderboard',
      updateSeasonCountdown: isSimulator ? 'simulator-update-season-countdown' : 'update-season-countdown',
      updateBlastGoldSnapshotCountdown: isSimulator
        ? 'simulator-update-blast-gold-snapshot-countdown'
        : 'update-blast-gold-snapshot-countdown',
      openLeaderboardModal: isSimulator ? 'simulator-open-leaderboard-modal' : 'open-leaderboard-modal',
      closeLeaderboardModal: isSimulator ? 'simulator-close-leaderboard-modal' : 'close-leaderboard-modal',
      updateLeaderboardList: isSimulator ? 'simulator-update-leaderboard-list' : 'update-leaderboard-list',
      requestLeaderboardList: isSimulator ? 'simulator-request-leaderboard-list' : 'request-leaderboard-list',
      updateMarketData: isSimulator ? 'simulator-update-market-data' : 'update-market-data',
      requestMarketData: isSimulator ? 'simulator-request-market-data' : 'request-market-data',
    };
    this.events = events;

    const leftMargin = this.popup.x - this.popup.width / 2;
    const paddedX = leftMargin + this.popup.width * 0.1;
    const startingY = this.popup.y - this.popup.height / 2;
    const youFinishedAtY = startingY + 150;
    const youFinisedAtSecondLineY = youFinishedAtY + 90;
    const gameEndsY = youFinishedAtY + 40;
    this.prizePoolContainerY = youFinisedAtSecondLineY + 250;
    const reputationY = this.prizePoolContainerY + 240;
    const leaderboardHeaderY = reputationY + 125;
    this.leaderboardY = leaderboardHeaderY + 55;

    this.gameComingSoon = scene.add
      .text(this.popup.x, gameEndsY, 'GAME WILL START AT', largeBlackExtraBold)
      .setOrigin(0.5, 0)
      .setVisible(false);
    this.gameStartTime = scene.add
      .text(this.popup.x, gameEndsY + 100, '', largeBlackExtraBold)
      .setOrigin(0.5, 0.5)
      .setVisible(false);
    this.gameEndsIn = scene.add.text(width / 2 - 40, gameEndsY, 'GAME ENDS IN: ', largeBlackExtraBold).setOrigin(1, 0);
    this.clockIcon = scene.add.image(width / 2 - 40, gameEndsY, 'icon-clock').setOrigin(0, 0);
    this.gameEndTime = scene.add.text(
      width / 2 + this.popup.width * 0.03,
      gameEndsY,
      '--d --h --m --s',
      largeBlackExtraBold
    );
    this.add(this.gameComingSoon);
    this.add(this.gameStartTime);
    this.add(this.gameEndsIn);
    this.add(this.clockIcon);
    this.add(this.gameEndTime);

    this.youFinished = scene.add
      .text(width / 2, youFinishedAtY, `You finished in`, mediumBlackBoldRight)
      .setOrigin(1, -0.13)
      .setVisible(false);
    this.with = scene.add
      .text(width / 2, youFinisedAtSecondLineY, `with`, mediumBlackBoldRight)
      .setOrigin(1, -0.12)
      .setVisible(false);
    this.finishedAt = scene.add.text(width * 0.51, youFinishedAtY, '', largeBlackExtraBold).setVisible(false);
    this.finishedReward = scene.add.text(width * 0.51, youFinisedAtSecondLineY, '', largeBlackBold).setVisible(false);
    this.finishedToken = scene.add.text(width * 0.51, youFinisedAtSecondLineY, '', largeBlackBold).setVisible(false);
    this.finishedXU = scene.add.text(width * 0.51, youFinisedAtSecondLineY, '', largeBlackBold).setVisible(false);
    this.finishedGold = scene.add.text(width * 0.51, youFinisedAtSecondLineY, '', largeBlackBold).setVisible(false);
    this.ethIcon = scene.add
      .image(width * 0.66, youFinisedAtSecondLineY, 'eth-coin')
      .setOrigin(0, 0.1)
      .setVisible(false);
    this.tokenIcon = scene.add
      .image(width * 0.66, youFinisedAtSecondLineY, 'gang-coin-small')
      .setOrigin(0, 0.1)
      .setDisplaySize(80, 80)
      .setVisible(false);
    this.xUIcon = scene.add
      .image(width * 0.66, youFinisedAtSecondLineY, 'icon-u-point')
      .setOrigin(0, 0.1)
      .setDisplaySize(80, 80)
      .setVisible(false);
    this.goldIcon = scene.add
      .image(width * 0.66, youFinisedAtSecondLineY, 'icon-blast-gold')
      .setOrigin(0, 0.1)
      .setDisplaySize(80, 80)
      .setVisible(false);
    this.add(this.youFinished);
    this.add(this.with);
    this.add(this.finishedAt);
    this.add(this.finishedReward);
    this.add(this.finishedToken);
    this.add(this.finishedXU);
    this.add(this.finishedGold);
    this.add(this.ethIcon);
    this.add(this.tokenIcon);
    this.add(this.xUIcon);
    this.add(this.goldIcon);

    const prizePoolContainer = scene.add
      .image(width / 2, this.prizePoolContainerY, 'text-container-outlined')
      .setOrigin(0.5, 0.5);
    const totalPrizePool = scene.add
      .text(width / 2, this.prizePoolContainerY - 50, 'Total Prize', largeBlackExtraBold)
      .setOrigin(0.5, 0.5);
    const infoButton = new Button(
      scene,
      width / 2 + totalPrizePool.width / 2 + 30,
      this.prizePoolContainerY - 75,
      'button-info',
      'button-info-pressed',
      () => {
        if (isSimulator) return;
        this.forceClose();
        scene.popupPrizePool.open();
      },
      { sound: 'open' }
    );

    const ethPrizePoolX = width / 2 - 250;
    const tokenPrizePoolX = ethPrizePoolX + 260;
    const blastGoldX = tokenPrizePoolX + 260;
    const xUPrizePoolX = width - paddedX;
    const iconSize = 84;
    this.prizePool = scene.add
      .text(ethPrizePoolX - 90, this.prizePoolContainerY + 50, '0.00', {
        fontSize: '50px',
        color: colors.black,
        fontFamily: fontFamilies.extraBold,
      })
      .setOrigin(1, 0.5);
    const ethIcon = scene.add
      .image(ethPrizePoolX, this.prizePoolContainerY + 50, 'icon-eth')
      .setOrigin(1, 0.5)
      .setDisplaySize(iconSize, iconSize);
    this.prizePoolTokenText = scene.add
      .text(tokenPrizePoolX - 90, this.prizePoolContainerY + 50, '0', {
        fontSize: '50px',
        color: colors.black,
        fontFamily: fontFamilies.extraBold,
      })
      .setOrigin(1, 0.5);
    const tokenIcon = scene.add
      .image(tokenPrizePoolX, this.prizePoolContainerY + 50, 'gang-coin-small')
      .setOrigin(1, 0.5)
      .setDisplaySize(iconSize, iconSize);
    this.prizePoolBlastGold = scene.add
      .text(blastGoldX - 90, this.prizePoolContainerY + 50, '0', {
        fontSize: '50px',
        color: colors.black,
        fontFamily: fontFamilies.extraBold,
      })
      .setOrigin(1, 0.5);
    const blastGoldIcon = scene.add
      .image(blastGoldX, this.prizePoolContainerY + 50, 'icon-blast-gold')
      .setOrigin(1, 0.5)
      .setDisplaySize(iconSize, iconSize);
    this.xUPrizePool = scene.add
      .text(xUPrizePoolX - 90, this.prizePoolContainerY + 50, '0', {
        fontSize: '50px',
        color: colors.black,
        fontFamily: fontFamilies.extraBold,
      })
      .setOrigin(1, 0.5);
    const uPointIcon = scene.add
      .image(xUPrizePoolX, this.prizePoolContainerY + 50, 'icon-u-point')
      .setOrigin(1, 0.5)
      .setDisplaySize(iconSize, iconSize);
    this.add(prizePoolContainer);
    this.add(totalPrizePool);
    this.add(infoButton);
    this.add(this.prizePool);
    this.add(ethIcon);
    this.add(this.prizePoolTokenText);
    this.add(tokenIcon);
    this.add(this.prizePoolBlastGold);
    this.add(blastGoldIcon);
    this.add(this.xUPrizePool);
    this.add(uPointIcon);

    const rank = scene.add.text(paddedX - 40, leaderboardHeaderY, 'Rank', smallBrownBold).setOrigin(0, 0.5);
    const name = scene.add
      .text(leftMargin + this.popup.width * 0.22, leaderboardHeaderY, 'Name', smallBrownBold)
      .setOrigin(0, 0.5);

    this.headerReputation = scene.add
      .text(leftMargin + this.popup.width * 0.48, leaderboardHeaderY, 'Reputation', smallBrownBold)
      .setOrigin(0, 0.5);
    this.headerTotalRewards = scene.add
      .text(leftMargin + this.popup.width * 0.7, leaderboardHeaderY, 'Reward Value\n(ETH)', {
        ...smallBrownBold,
        align: 'center',
      })
      .setOrigin(0, 0.5);
    const infoBtnX = this.headerTotalRewards.x + this.headerTotalRewards.width - 30;
    const infoBtnY = this.headerTotalRewards.y + 25;
    this.tooltipRewards = scene.add.container().setVisible(false);
    const tooltipRewardsImg = scene.add.image(infoBtnX, infoBtnY - 10, 'tooltip-leaderboard').setOrigin(0.5, 1);
    this.tooltipRewardsEthText = scene.add
      .text(infoBtnX + 30, infoBtnY - 375, '--% in', mediumBlackBoldRight)
      .setOrigin(1, 0);
    this.tooltipRewardsTokenText = scene.add
      .text(infoBtnX + 30, this.tooltipRewardsEthText.y + 85, '--% in', mediumBlackBoldRight)
      .setOrigin(1, 0);
    this.tooltipRewards.add(tooltipRewardsImg);
    this.tooltipRewards.add(this.tooltipRewardsEthText);
    this.tooltipRewards.add(this.tooltipRewardsTokenText);
    this.rewardsInfoButton = new Button(
      scene,
      infoBtnX,
      infoBtnY,
      'button-info',
      'button-info-pressed',
      () => {
        if (isSimulator) return;
        this.tooltipRewards.setVisible(!this.tooltipRewards.visible);
      },
      { sound: 'open', scale: 0.7 }
    );
    this.popup.setInteractive().on(Phaser.Input.Events.GAMEOBJECT_POINTER_DOWN, (pointer, localX, localY, event) => {
      if (isSimulator) return;
      this.tooltipRewards.setVisible(false);
    });
    this.headerRaidPts = scene.add
      .text(leftMargin + this.popup.width * 0.5, leaderboardHeaderY, 'Raid Points', smallBrownBold)
      .setOrigin(0, 0.5)
      .setVisible(false);
    this.headerUPoints = scene.add
      .text(leftMargin + this.popup.width * 0.49, leaderboardHeaderY, 'U Points', smallBrownBold)
      .setOrigin(0, 0.5)
      .setVisible(false);
    this.headerXU = scene.add
      .text(leftMargin + this.popup.width * 0.64, leaderboardHeaderY, '$xU\nRewards', {
        ...smallBrownBold,
        align: 'center',
      })
      .setOrigin(0, 0.5)
      .setVisible(false);
    this.headerBlastGold = scene.add
      .text(leftMargin + this.popup.width * 0.81, leaderboardHeaderY, 'Blast\nGold', {
        ...smallBrownBold,
        align: 'center',
      })
      .setOrigin(0, 0.5)
      .setVisible(false);

    this.add(rank);
    this.add(name);
    this.add(this.headerReputation);
    this.add(this.headerTotalRewards);
    this.add(this.rewardsInfoButton);
    this.add(this.headerRaidPts);
    this.add(this.headerUPoints);
    this.add(this.headerXU);
    this.add(this.headerBlastGold);

    this.leaderboardContainer = scene.add.image(width / 2, this.leaderboardY, 'container-medium').setOrigin(0.5, 0);
    this.add(this.leaderboardContainer);

    this.contentContainer = scene.add.container().setSize(200, 0);

    const playerRankY = this.leaderboardY + this.leaderboardContainer.height;
    const playerOriginY = 2;
    this.playerRankContainer = scene.add.image(width / 2, playerRankY, 'player-rank-container').setOrigin(0.52, 1.2);
    this.playerRank = scene.add
      .text(paddedX + this.popup.width * 0.01, playerRankY, '', smallBrownExtraBold)
      .setOrigin(0.5, playerOriginY);
    this.playerUsername = scene.add
      .text(paddedX + this.popup.width * 0.09, playerRankY, 'YOU', smallBrownExtraBold)
      .setOrigin(0, playerOriginY);
    this.playerNetworth = scene.add
      .text(paddedX + this.popup.width * 0.45, playerRankY, '', smallBrownExtraBold)
      .setOrigin(0.5, playerOriginY);
    this.playerStar = scene.add
      .image(paddedX + this.popup.width * 0.45, playerRankY, 'icon-star')
      .setOrigin(0, playerOriginY);
    this.playerReward = scene.add
      .text(paddedX + this.popup.width * 0.7, playerRankY, '', smallBrownExtraBold)
      .setOrigin(0.5, playerOriginY);
    this.playerRaidPoints = scene.add
      .text(paddedX + this.popup.width * 0.52, playerRankY, '', smallBrownExtraBold)
      .setOrigin(1, playerOriginY)
      .setVisible(false);
    this.playerUPoints = scene.add
      .text(paddedX + this.popup.width * 0.45, playerRankY, '', smallBrownExtraBold)
      .setOrigin(0.5, playerOriginY)
      .setVisible(false);
    this.playerXUReward = scene.add
      .text(paddedX + this.popup.width * 0.6, playerRankY, '', smallBrownExtraBold)
      .setOrigin(0.5, playerOriginY)
      .setVisible(false);
    this.playerBlastGold = scene.add
      .text(paddedX + this.popup.width * 0.78, playerRankY, '', smallBrownExtraBold)
      .setOrigin(1, playerOriginY)
      .setVisible(false);

    this.add(this.playerRankContainer);
    this.add(this.playerRank);
    this.add(this.playerUsername);
    this.add(this.playerStar);
    this.add(this.playerNetworth);
    this.add(this.playerReward);
    this.add(this.playerRaidPoints);
    this.add(this.playerUPoints);
    this.add(this.playerXUReward);
    this.add(this.playerBlastGold);

    this.modeSwitch = new ModeSwitch(scene, width / 2, reputationY, {
      modeOne: {
        title: 'Reputation',
        key: 'reputation',
        onClick: () => {
          // hide
          this.toggleRaidPointsItems(false);
          this.toggleUPointsItems(false);
          // show
          this.toggleReputationItems(true);

          this.changePage(0);
          this.updatePagination();
          this.updateLeaderboard();
        },
      },
      modeTwo: {
        title: 'Raid Rank',
        key: 'raidPoints',
        onClick: () => {
          // hide
          this.toggleReputationItems(false);
          this.toggleUPointsItems(false);
          // show
          this.toggleRaidPointsItems(true);

          this.changePage(0);
          this.updatePagination();
          this.updateLeaderboard();
        },
      },
      modeThree: {
        title: 'U Points',
        key: 'uPoints',
        onClick: () => {
          // hide
          this.toggleReputationItems(false);
          this.toggleRaidPointsItems(false);
          // show
          this.toggleUPointsItems(true);

          this.changePage(0);
          this.updatePagination();
          this.updateLeaderboard();
        },
      },
    });
    this.add(this.modeSwitch);
    this.add(this.tooltipRewards);

    this.buttonBack = new TextButton(
      scene,
      width / 2,
      height / 2 + this.popup.height / 2 - 20,
      'button-blue',
      'button-blue-pressed',
      () => {
        this.forceClose();
      },
      'Back',
      { fontSize: '82px', sound: 'close' }
    );
    this.add(this.buttonBack);

    this.nextSeason = scene.add
      .text(width / 2, height / 2 + this.popup.height / 2 - 80, 'Next season will begin soon', {
        fontSize: fontSizes.large,
        color: colors.brown,
        fontFamily: fontFamilies.bold,
      })
      .setOrigin(0.5, 1)
      .setVisible(false);
    this.add(this.nextSeason);

    // blast gold snapshot
    this.blastGoldSnapshot = scene.add
      .text(width / 2, height / 2 + this.popup.height / 2 - 250, 'Blast Gold rewards based on snapshot in:', {
        fontSize: fontSizes.medium,
        color: colors.black,
        fontFamily: fontFamilies.bold,
      })
      .setOrigin(0.5, 0.5)
      .setVisible(false);
    this.blastGoldSnapshotClockIcon = scene.add
      .image(width / 2 - this.popup.width * 0.2 - 40, this.blastGoldSnapshot.y + 40, 'icon-clock')
      .setOrigin(0, 0)
      .setVisible(false);
    this.blastGoldSnapshotTime = scene.add
      .text(this.blastGoldSnapshotClockIcon.x + 80, this.blastGoldSnapshotClockIcon.y, '--d --h --m --s', {
        ...largeBlackExtraBold,
        fontFamily: fontFamilies.bold,
      })
      .setVisible(false);
    this.add(this.blastGoldSnapshot);
    this.add(this.blastGoldSnapshotClockIcon);
    this.add(this.blastGoldSnapshotTime);

    this.loadingIcon = scene.add
      .image(
        this.popup.x,
        this.leaderboardContainer.y + this.leaderboardContainer.height / 2 - 50,
        'icon-loading-small'
      )
      .setVisible(false);
    this.add(this.loadingIcon);
    this.loadingAnimation = scene.tweens.add({
      targets: this.loadingIcon,
      rotation: Math.PI * 2, // full circle
      duration: 3000,
      repeat: -1, // infinite
      ease: 'Cubic.out',
    });
    this.loadingAnimation.pause();

    scene.game.events.on(events.updateSeason, (data) => this.updateValues(data));
    // scene.game.events.on(events.updateLeaderboard, (data) => this.updateLeaderboard(data));
    scene.game.events.on(events.updateSeasonCountdown, ({ countdownString, startTimeString, started }) => {
      this.started = started;
      if (!started) {
        this.gameEndsIn.setVisible(false);
        this.gameEndTime.setVisible(false);
        this.clockIcon.setVisible(false);
        this.gameComingSoon.setVisible(true);
        this.gameStartTime.text = `${startTimeString}`;
        this.gameStartTime.setVisible(true);
        return;
      }

      this.gameStartTime.setVisible(false);
      this.gameComingSoon.setVisible(false);
      this.gameEndsIn.setVisible(!this.isEnded);
      this.gameEndTime.setVisible(!this.isEnded);
      this.clockIcon.setVisible(!this.isEnded);

      const isPastGameEndTime = countdownString !== '--d --h --m --s' && countdownString.includes('-');
      this.gameEndsIn.text = isPastGameEndTime ? 'GAME ENDED. ' : 'GAME ENDS IN: ';
      this.gameEndTime.text = isPastGameEndTime ? ' CALCULATING...' : countdownString;
    });
    scene.game.events.on(events.updateBlastGoldSnapshotCountdown, ({ countdownString, snapshotString }) => {
      const isPastSnapshotTime = countdownString !== '--d --h --m --s' && countdownString.includes('-');
      this.blastGoldSnapshot.text = isPastSnapshotTime
        ? 'Blast Gold rewards snapshot taken on:'
        : 'Blast Gold rewards based on snapshot in:';
      this.blastGoldSnapshotTime.text = isPastSnapshotTime ? `${snapshotString} UTC` : countdownString;

      if (isPastSnapshotTime) {
        const timeTextWidth = this.blastGoldSnapshotTime.width + this.blastGoldSnapshotClockIcon.width + 40;
        this.blastGoldSnapshotClockIcon.setX(width / 2 - timeTextWidth / 2);
        this.blastGoldSnapshotTime.setX(this.blastGoldSnapshotClockIcon.x + 80);
      }
    });
    scene.game.events.on(events.updateMarketData, ({ tokenPrice }) => {
      this.tokenPrice = tokenPrice;
      this.updatePrizePoolPercentage();
    });
    scene.game.events.on(this.events.updateLeaderboardList, ({ totalPages, users, userRecord }) => {
      if (!this.visible) return;
      this.totalPages = totalPages;
      this.leaderboardUsers = users;
      this.userRecord = userRecord;
      this.updatePagination();
      this.updateLeaderboard();
      this.hideLoading();
      this.loading = false;
    });

    scene.game.events.emit(events.requestMarketData);
    scene.game.events.emit('request-active-status');
  }

  showLoading() {
    this.loadingAnimation.resume();
    this.loadingIcon.setVisible(true);
    this.items.forEach((item) => item.setAlpha(0.5));
  }

  hideLoading() {
    this.loadingIcon.setVisible(false);
    this.loadingAnimation.pause();
    this.items.forEach((item) => item.setAlpha(1));
  }

  onOpen() {
    if (this.table) {
      this.table.setMouseWheelScrollerEnable(true);
    }
    this.scene.game.events.emit(this.events?.openLeaderboardModal || '');

    this.reloadData();
  }

  cleanup() {
    this.table?.scrollToTop();
    if (this.table) {
      this.table.setMouseWheelScrollerEnable(false);
      this.leaderboardThumb?.setVisible(false);
    }
    this.scene.game.events.emit(this.events?.closeLeaderboardModal || '');
    clearTimeout(this.timeoutId);

    for (let key in this.pages) {
      this.pages[key] = 0;
    }
  }

  updatePrizePoolPercentage() {
    const tokenPrizePoolInEth = this.prizePoolToken * this.tokenPrice;
    const totalPrizePoolInEth = this.prizePoolEth + tokenPrizePoolInEth;
    const ethRewardsPercent = this.prizePoolEth / (totalPrizePoolInEth || 1);

    this.tooltipRewardsEthText.text = `${Math.round(ethRewardsPercent * 100)}% in`;
    this.tooltipRewardsTokenText.text = `${Math.round((1 - ethRewardsPercent) * 100)}% in`;
  }
  updateValues(season) {
    const { name, prizePoolEth, prizePoolToken, blastGoldReward, xUPool, isEnded } = season;

    this.isEnded = isEnded;
    this.updateEndedState();
    const title = this.isEnded ? `${name} Ended` : `${name} Leaderboard`;
    this.setTitle(title);
    this.prizePoolEth = prizePoolEth;
    this.prizePoolToken = prizePoolToken;
    this.updatePrizePoolPercentage();
    this.prizePool.text = customFormat(prizePoolEth, 2);
    this.prizePoolTokenText.text = customFormat(prizePoolToken || 0, 0);
    this.prizePoolBlastGold.text = customFormat(blastGoldReward || 0, 0);
    this.xUPrizePool.text = customFormat(xUPool || 0, 2);
  }

  updateEndedState() {
    const isUPointLeaderboard = this.modeSwitch.modeKey === 'uPoints';
    this.gameEndsIn.setVisible(!this.isEnded && this.started);
    this.clockIcon.setVisible(!this.isEnded && this.started);
    this.gameComingSoon.setVisible(!this.isEnded && !this.started);
    this.gameStartTime.setVisible(!this.isEnded && !this.started);
    this.gameEndTime.setVisible(!this.isEnded && this.started);
    this.buttonBack.setVisible(!this.isEnded && this.started);
    if (this.isEnded) {
      if (!this.isSimulator) {
        this.remove(this.buttonBack);
      }
      let topText;
      let bottomText;
      let textAlign;
      let textOriginX;
      let bottomTextOriginY;

      if (this.isUserActive) {
        topText = 'You finished in';
        bottomText = 'with';
        textAlign = 'right';
        textOriginX = 1;
        bottomTextOriginY = -0.12;
      } else {
        topText = 'You did not qualify for the';
        bottomText = 'leaderboard this season.';
        textAlign = 'center';
        textOriginX = 0.5;
        bottomTextOriginY = 0.2;
      }

      this.youFinished.text = topText;
      this.with.text = bottomText;

      this.youFinished.setStyle({ ...mediumBlackBoldRight, align: textAlign });
      this.youFinished.setOrigin(textOriginX, -0.13);
      this.with.setStyle({ ...mediumBlackBoldRight, align: textAlign });
      this.with.setOrigin(textOriginX, bottomTextOriginY);
    } else {
      this.add(this.buttonBack);
    }

    this.youFinished.setVisible(this.isEnded);
    this.finishedAt.setVisible(this.isEnded && this.isUserActive);
    this.with.setVisible(this.isEnded);
    this.finishedReward.setVisible(this.isEnded && this.isUserActive && !isUPointLeaderboard);
    this.finishedToken.setVisible(this.isEnded && this.isUserActive && !isUPointLeaderboard);
    this.finishedXU.setVisible(this.isEnded && this.isUserActive && isUPointLeaderboard);
    this.finishedGold.setVisible(this.isEnded && this.isUserActive && isUPointLeaderboard);
    this.ethIcon.setVisible(this.finishedReward.visible);
    this.tokenIcon.setVisible(this.finishedToken.visible);
    this.xUIcon.setVisible(this.finishedXU.visible);
    this.goldIcon.setVisible(this.finishedGold.visible);
    this.nextSeason.setVisible(this.isEnded && !this.isSimulator);
  }

  updateLeaderboard() {
    const isRankingMode = this.modeSwitch.modeKey === 'reputation';
    const leaderboard = this.leaderboardUsers[this.modeSwitch.modeKey];
    const activeLeaderboard = leaderboard.filter((item) => item.active);
    this.users = leaderboard.filter((item) => item.active).slice(0, 3);
    this.items.map((item) => {
      this.contentContainer.remove(item);
      item.destroy();
    });

    const avatarSize = 72;
    this.items = [];
    this.avatars = {};
    const firstNetworthText = this.scene.add
      .text(
        this.popup.width * 0.45,
        rowHeight / 2,
        isRankingMode && this.users[0] ? formatter.format(Math.floor(this.users[0].networth)) : '',
        {
          ...smallBlackBold,
          align: 'right',
        }
      )
      .setOrigin(0.5, 0.5);

    for (let i = 0; i < 3; i++) {
      const y = i * rowHeight;

      if (this.modeSwitch.modeKey === 'reputation') {
        if (!this.users[i]) break;
        const { userId, rank, username, networth, totalRewardInEth } = this.users[i];
        const rankText = this.scene.add.text(0, y + rowHeight / 2, `${rank}`, smallBlackBold).setOrigin(0, 0.5);

        const usernameText = this.scene.add
          .text(
            this.popup.width * 0.14,
            y + rowHeight / 2,
            formatUsername({ username, MAX_USERNAME_LENGTH: 12 }),
            smallBlackBold
          )
          .setOrigin(0, 0.5);
        const usernameUnderline = this.scene.add
          .rectangle(usernameText.x, usernameText.y + 20, usernameText.width, 2, 0x29000b)
          .setOrigin(0, 0.5);

        usernameText.setInteractive().on(Phaser.Input.Events.GAMEOBJECT_POINTER_DOWN, () => {
          this.forceClose();
          this.scene.popupWarAttackDetail?.hideRaidBtn();
          this.scene.popupWarAttackDetail?.viewUser({
            userId,
            onBack: () => {
              this.open();
            },
          });
        });

        const avatar = this.scene.add
          .rexCircleMaskImage(usernameText.x - 90, y + rowHeight / 2, 'avatar')
          .setOrigin(0, 0.5);

        const networthText =
          i === 0
            ? firstNetworthText
            : this.scene.add
                .text(
                  firstNetworthText.x + firstNetworthText.width / 2,
                  y + rowHeight / 2,
                  formatter.format(Math.floor(networth)),
                  {
                    ...smallBlackBold,
                    align: 'right',
                  }
                )
                .setOrigin(1, 0.5);
        const star = this.scene.add
          .image(firstNetworthText.x + firstNetworthText.width / 2 + 10, y + rowHeight / 2, 'icon-star')
          .setOrigin(0, 0.5);
        const rewardText = this.scene.add
          .text(this.popup.width * 0.7, y + rowHeight / 2, `${formatter.format(totalRewardInEth)}`, {
            ...smallBlackBold,
            align: 'center',
          })
          .setOrigin(0.5, 0.5);

        this.avatars[username] = avatar;
        this.items.push(rankText, avatar, usernameText, usernameUnderline, networthText, star, rewardText);
      } else if (this.modeSwitch.modeKey === 'raidPoints') {
        if (!this.users[i]) break;
        const { userId, rank, username, raidPoint, totalRewardInEth } = this.users[i];
        const rankText = this.scene.add.text(0, y + rowHeight / 2, `${rank}`, smallBlackBold).setOrigin(0, 0.5);

        const usernameText = this.scene.add
          .text(
            this.popup.width * 0.14,
            y + rowHeight / 2,
            formatUsername({ username, MAX_USERNAME_LENGTH: 12 }),
            smallBlackBold
          )
          .setOrigin(0, 0.5);
        const usernameUnderline = this.scene.add
          .rectangle(usernameText.x, usernameText.y + 20, usernameText.width, 2, 0x29000b)
          .setOrigin(0, 0.5);

        usernameText.setInteractive().on(Phaser.Input.Events.GAMEOBJECT_POINTER_DOWN, () => {
          this.forceClose();
          this.scene.popupWarAttackDetail?.hideRaidBtn();
          this.scene.popupWarAttackDetail?.viewUser({
            userId,
            onBack: () => {
              this.open();
            },
          });
        });

        const avatar = this.scene.add
          .rexCircleMaskImage(usernameText.x - 90, y + rowHeight / 2, 'avatar')
          .setOrigin(0, 0.5);
        const raidPointText = this.scene.add
          .text(this.popup.width * 0.52, y + rowHeight / 2, customFormat(raidPoint || 0, 2), {
            ...smallBlackBold,
            align: 'right',
          })
          .setOrigin(1, 0.5);
        const rewardText = this.scene.add
          .text(this.popup.width * 0.7, y + rowHeight / 2, `${formatter.format(totalRewardInEth)}`, {
            ...smallBlackBold,
            align: 'center',
          })
          .setOrigin(0.5, 0.5);

        this.avatars[username] = avatar;
        this.items.push(avatar, rankText, usernameText, usernameUnderline, raidPointText, rewardText);
      } else {
        if (!this.users[i]) break;
        const { userId, rank, username, uPoint, xUReward, blastGoldReward } = this.users[i];
        const rankText = this.scene.add.text(0, y + rowHeight / 2, `${rank}`, smallBlackBold).setOrigin(0, 0.5);

        const usernameText = this.scene.add
          .text(
            this.popup.width * 0.14,
            y + rowHeight / 2,
            formatUsername({ username, MAX_USERNAME_LENGTH: 12 }),
            smallBlackBold
          )
          .setOrigin(0, 0.5);
        const usernameUnderline = this.scene.add
          .rectangle(usernameText.x, usernameText.y + 20, usernameText.width, 2, 0x29000b)
          .setOrigin(0, 0.5);

        usernameText.setInteractive().on(Phaser.Input.Events.GAMEOBJECT_POINTER_DOWN, () => {
          this.forceClose();
          this.scene.popupWarAttackDetail?.hideRaidBtn();
          this.scene.popupWarAttackDetail?.viewUser({
            userId,
            onBack: () => {
              this.open();
            },
          });
        });

        const avatar = this.scene.add
          .rexCircleMaskImage(usernameText.x - 90, y + rowHeight / 2, 'avatar')
          .setOrigin(0, 0.5);
        const uPointText = this.scene.add
          .text(this.popup.width * 0.46, y + rowHeight / 2, customFormat(uPoint || 0, 1), {
            ...smallBlackBold,
            align: 'center',
          })
          .setOrigin(0.5, 0.5);
        const xURewardText = this.scene.add
          .text(this.popup.width * 0.61, y + rowHeight / 2, customFormat(xUReward || 0, 1), {
            ...smallBlackBold,
            align: 'center',
          })
          .setOrigin(0.5, 0.5);
        const blastGoldRewardText = this.scene.add
          .text(this.popup.width * 0.8, y + rowHeight / 2, `${customFormat(blastGoldReward || 0, 1)}`, {
            ...smallBlackBold,
            align: 'right',
          })
          .setOrigin(1, 0.5);

        this.avatars[username] = avatar;
        this.items.push(
          avatar,
          rankText,
          usernameText,
          usernameUnderline,
          uPointText,
          xURewardText,
          blastGoldRewardText
        );
      }
    }
    this.contentContainer.add(this.items);

    const avatarUsers = this.leaderboardUsers[this.modeSwitch.modeKey];
    // load avatar
    let loader = new Phaser.Loader.LoaderPlugin(this.scene);
    let textureManager = new Phaser.Textures.TextureManager(this.scene.game);
    avatarUsers.slice(0, 3).forEach(({ username, avatarURL_small, avatarURL }) => {
      // ask the LoaderPlugin to load the texture
      if (!textureManager.exists(`${username}-avatar`))
        loader.image(`${username}-avatar`, avatarURL_small || avatarURL);
    });

    loader.once(Phaser.Loader.Events.COMPLETE, () =>
      Object.keys(this.avatars).forEach((username) => {
        const avatar = this.avatars[username];
        avatar.setTexture(`${username}-avatar`);
        avatar.setDisplaySize(avatarSize, avatarSize);
      })
    );
    loader.start();

    this.renderLeaderBoard(activeLeaderboard, 3, 50, firstNetworthText.width);

    const userRecord = this.userRecord[this.modeSwitch.modeKey];
    if (!userRecord) return;
    this.isUserActive = userRecord.active;
    this.updateEndedState();

    this.playerRank.text = userRecord.rank.toLocaleString();
    this.playerNetworth.text = formatter.format(Math.floor(this.userRecord.reputation.networth));
    this.playerStar.x = this.playerNetworth.x + this.playerNetworth.width / 2 + 10;
    this.playerReward.text = `${formatter.format(userRecord.totalRewardInEth)}`;
    this.playerRaidPoints.text = `${Math.floor(this.userRecord.raidPoints.raidPoint || 0).toLocaleString()}`;
    this.playerUPoints.text = `${customFormat(this.userRecord.uPoints.uPoint || 0, 1)}`;
    this.playerXUReward.text = `${customFormat(this.userRecord.uPoints.xUReward || 0, 1)}`;
    this.playerBlastGold.text = `${customFormat(this.userRecord.uPoints.blastGoldReward || 0, 1)}`;
    this.finishedAt.text = `${userRecord.rank.toLocaleString()}${getOrdinalSuffix(userRecord.rank)} place`;

    const isUPointLeaderboard = this.modeSwitch.modeKey === 'uPoints';
    this.finishedReward.text = formatter.format(this.userRecord[this.modeSwitch.modeKey].ethReward || 0);
    this.finishedToken.text = `+ ${formatter.format(this.userRecord[this.modeSwitch.modeKey].tokenReward || 0)}`;
    this.finishedXU.text = `${customFormat(this.userRecord.uPoints.xUReward || 0, 2)}`;
    this.finishedGold.text = `+ ${customFormat(this.userRecord.uPoints.blastGoldReward || 0, 2)}`;

    // re-align texts to center
    const topTextWidth = this.youFinished.width + this.finishedAt.width + 20;
    this.youFinished.setX(this.isUserActive ? width / 2 - topTextWidth / 2 + this.youFinished.width : width / 2);
    this.finishedAt.setX(this.youFinished.x + 20);
    const prizeTextsWidth = isUPointLeaderboard
      ? this.finishedXU.width + this.xUIcon.width + this.finishedGold.width + this.goldIcon.width + 40
      : this.finishedReward.width + this.ethIcon.width + this.finishedToken.width + this.tokenIcon.width + 40;
    const bottomTextWidth = this.with.width + prizeTextsWidth + 20;
    this.with.setX(this.isUserActive ? width / 2 - bottomTextWidth / 2 + this.with.width : width / 2);
    if (isUPointLeaderboard) {
      this.finishedXU.setX(this.with.x + 20);
      this.xUIcon.setX(this.finishedXU.x + this.finishedXU.width + 10);
      this.finishedGold.setX(this.xUIcon.x + this.xUIcon.width + 20);
      this.goldIcon.setX(this.finishedGold.x + this.finishedGold.width + 10);
    } else {
      this.finishedReward.setX(this.with.x + 20);
      this.ethIcon.setX(this.finishedReward.x + this.finishedReward.width + 10);
      this.finishedToken.setX(this.ethIcon.x + this.ethIcon.width + 20);
      this.tokenIcon.setX(this.finishedToken.x + this.finishedToken.width + 10);
    }

    if (this.numberOfRows === activeLeaderboard.length) return;
    this.numberOfRows = activeLeaderboard.length;

    // redraw table if numberOfRows changed
    const contentContainerHeight = activeLeaderboard.length * rowHeight;
    this.contentContainer.setSize(200, contentContainerHeight);
    if (this.table) {
      this.remove(this.table);
      this.table.destroy(true);
      this.table = null;
    }

    if (this.leaderboardThumb) {
      this.remove(this.leaderboardThumb);
      this.leaderboardThumb.destroy(true);
    }

    const tableHeight = this.leaderboardContainer.height - this.playerRankContainer.height;
    const visibleRatio = tableHeight / this.contentContainer.height;
    this.leaderboardThumb = this.scene.rexUI.add
      .roundRectangle({
        height: visibleRatio < 1 ? tableHeight * visibleRatio : 0,
        radius: 13,
        color: 0xe3d6c7,
      })
      .setVisible(false);

    this.table = new ScrollablePanel(this.scene, {
      x: width / 2,
      y: this.leaderboardY + tableHeight / 2,
      width: this.leaderboardContainer.width,
      height: tableHeight,
      scrollMode: 'y',
      background: this.scene.rexUI.add.roundRectangle({ radius: 10 }),
      panel: { child: this.contentContainer, mask: { padding: 1 } },
      slider: { thumb: this.leaderboardThumb },
      mouseWheelScroller: { focus: true, speed: 0.3 },
      space: { left: 40, right: 40, top: 40, bottom: 40, panel: 20, header: 10, footer: 10 },
    }).layout();
    if (this.leaderboardUsers.length <= 8 || !this.visible) {
      this.table.setScrollerEnable(false); // drag scrolling
      this.table.setMouseWheelScrollerEnable(false); // mouse-wheel scrolling
    } else {
      this.table.setScrollerEnable(true);
      this.table.setMouseWheelScrollerEnable(true);
    }
    this.add(this.table);

    this.table.on('scroll', (e) => {
      // console.log('scroll', e.t); // e.t === scrolled percentage
      if (this.leaderboardThumb.visible) return;
      this.leaderboardThumb.setVisible(true);
    });
  }

  renderLeaderBoard(activeLeaderboard, startRows, packCount, firstNetworthWidth) {
    if (activeLeaderboard.length > startRows + packCount) {
      this.timeoutId = setTimeout(() => {
        this.renderLeaderBoard(
          activeLeaderboard,
          startRows + packCount,
          Math.min(packCount, activeLeaderboard.length - startRows),
          firstNetworthWidth
        );
      }, 500);
    }

    const currentPackLeaderboard = activeLeaderboard
      .filter((item) => item.active)
      .slice(startRows, startRows + packCount);
    let users = currentPackLeaderboard;
    const avatarSize = 72;
    let items = [];

    for (let i = 0; i < users.length; i++) {
      const y = (i + startRows) * rowHeight;

      if (this.modeSwitch.modeKey === 'reputation') {
        const { userId, rank, username, networth, totalRewardInEth } = users[i];
        const rankText = this.scene.add.text(0, y + rowHeight / 2, `${rank}`, smallBlackBold).setOrigin(0, 0.5);

        const usernameText = this.scene.add
          .text(
            this.popup.width * 0.14,
            y + rowHeight / 2,
            formatUsername({ username, MAX_USERNAME_LENGTH: 12 }),
            smallBlackBold
          )
          .setOrigin(0, 0.5);
        const usernameUnderline = this.scene.add
          .rectangle(usernameText.x, usernameText.y + 20, usernameText.width, 2, 0x29000b)
          .setOrigin(0, 0.5);
        usernameText.setInteractive().on(Phaser.Input.Events.GAMEOBJECT_POINTER_DOWN, () => {
          this.forceClose();
          this.scene.popupWarAttackDetail?.hideRaidBtn();
          this.scene.popupWarAttackDetail?.viewUser({
            userId,
            onBack: () => {
              this.open();
            },
          });
        });

        const avatar = this.scene.add
          .rexCircleMaskImage(usernameText.x - 90, y + rowHeight / 2, 'avatar')
          .setOrigin(0, 0.5);
        const networthText =
          i === 0 && startRows === 0
            ? this.scene.add
                .text(
                  this.popup.width * 0.45,
                  rowHeight / 2,
                  activeLeaderboard[0] ? formatter.format(Math.floor(activeLeaderboard[0].networth)) : '',
                  {
                    ...smallBlackBold,
                    align: 'right',
                  }
                )
                .setOrigin(0.5, 0.5)
            : this.scene.add
                .text(
                  this.popup.width * 0.45 + firstNetworthWidth / 2,
                  y + rowHeight / 2,
                  formatter.format(Math.floor(networth)),
                  {
                    ...smallBlackBold,
                    align: 'right',
                  }
                )
                .setOrigin(1, 0.5);
        const star = this.scene.add
          .image(this.popup.width * 0.45 + firstNetworthWidth / 2 + 10, y + rowHeight / 2, 'icon-star')
          .setOrigin(0, 0.5);
        const rewardText = this.scene.add
          .text(this.popup.width * 0.7, y + rowHeight / 2, `${formatter.format(totalRewardInEth)}`, {
            ...smallBlackBold,
            align: 'center',
          })
          .setOrigin(0.5, 0.5);

        this.avatars[username] = avatar;
        this.items.push(rankText, avatar, usernameText, usernameUnderline, networthText, star, rewardText);
        items.push(rankText, avatar, usernameText, usernameUnderline, networthText, star, rewardText);
      } else if (this.modeSwitch.modeKey === 'raidPoints') {
        const { userId, rank, username, raidPoint, totalRewardInEth } = users[i];
        const rankText = this.scene.add.text(0, y + rowHeight / 2, `${rank}`, smallBlackBold).setOrigin(0, 0.5);

        const usernameText = this.scene.add
          .text(
            this.popup.width * 0.14,
            y + rowHeight / 2,
            formatUsername({ username, MAX_USERNAME_LENGTH: 12 }),
            smallBlackBold
          )
          .setOrigin(0, 0.5);
        const usernameUnderline = this.scene.add
          .rectangle(usernameText.x, usernameText.y + 20, usernameText.width, 2, 0x29000b)
          .setOrigin(0, 0.5);

        usernameText.setInteractive().on(Phaser.Input.Events.GAMEOBJECT_POINTER_DOWN, () => {
          this.forceClose();
          this.scene.popupWarAttackDetail?.hideRaidBtn();
          this.scene.popupWarAttackDetail?.viewUser({
            userId,
            onBack: () => {
              this.open();
            },
          });
        });

        const avatar = this.scene.add
          .rexCircleMaskImage(usernameText.x - 90, y + rowHeight / 2, 'avatar')
          .setOrigin(0, 0.5);
        const raidPointText = this.scene.add
          .text(this.popup.width * 0.52, y + rowHeight / 2, customFormat(raidPoint || 0, 2), {
            ...smallBlackBold,
            align: 'right',
          })
          .setOrigin(1, 0.5);
        const rewardText = this.scene.add
          .text(this.popup.width * 0.7, y + rowHeight / 2, `${formatter.format(totalRewardInEth)}`, {
            ...smallBlackBold,
            align: 'center',
          })
          .setOrigin(0.5, 0.5);

        this.avatars[username] = avatar;
        this.items.push(avatar, rankText, usernameText, usernameUnderline, raidPointText, rewardText);
        items.push(avatar, rankText, usernameText, usernameUnderline, raidPointText, rewardText);
      } else {
        const { userId, rank, username, uPoint, xUReward, blastGoldReward } = users[i];
        const rankText = this.scene.add.text(0, y + rowHeight / 2, `${rank}`, smallBlackBold).setOrigin(0, 0.5);

        const usernameText = this.scene.add
          .text(
            this.popup.width * 0.14,
            y + rowHeight / 2,
            formatUsername({ username, MAX_USERNAME_LENGTH: 12 }),
            smallBlackBold
          )
          .setOrigin(0, 0.5);
        const usernameUnderline = this.scene.add
          .rectangle(usernameText.x, usernameText.y + 20, usernameText.width, 2, 0x29000b)
          .setOrigin(0, 0.5);

        usernameText.setInteractive().on(Phaser.Input.Events.GAMEOBJECT_POINTER_DOWN, () => {
          this.forceClose();
          this.scene.popupWarAttackDetail?.hideRaidBtn();
          this.scene.popupWarAttackDetail?.viewUser({
            userId,
            onBack: () => {
              this.open();
            },
          });
        });

        const avatar = this.scene.add
          .rexCircleMaskImage(usernameText.x - 90, y + rowHeight / 2, 'avatar')
          .setOrigin(0, 0.5);
        const uPointText = this.scene.add
          .text(this.popup.width * 0.46, y + rowHeight / 2, customFormat(uPoint || 0, 1), {
            ...smallBlackBold,
            align: 'center',
          })
          .setOrigin(0.5, 0.5);
        const xURewardText = this.scene.add
          .text(this.popup.width * 0.61, y + rowHeight / 2, customFormat(xUReward || 0, 1), {
            ...smallBlackBold,
            align: 'center',
          })
          .setOrigin(0.5, 0.5);
        const blastGoldRewardText = this.scene.add
          .text(this.popup.width * 0.8, y + rowHeight / 2, `${customFormat(blastGoldReward || 0, 1)}`, {
            ...smallBlackBold,
            align: 'right',
          })
          .setOrigin(1, 0.5);

        this.avatars[username] = avatar;
        this.items.push(
          avatar,
          rankText,
          usernameText,
          usernameUnderline,
          uPointText,
          xURewardText,
          blastGoldRewardText
        );
        items.push(avatar, rankText, usernameText, usernameUnderline, uPointText, xURewardText, blastGoldRewardText);
      }
    }
    this.contentContainer.add(items);

    const avatarUsers = this.leaderboardUsers[this.modeSwitch.modeKey];
    // load avatar
    let loader = new Phaser.Loader.LoaderPlugin(this.scene);
    let textureManager = new Phaser.Textures.TextureManager(this.scene.game);
    avatarUsers.slice(startRows, startRows + packCount).forEach(({ username, avatarURL_small, avatarURL }) => {
      // ask the LoaderPlugin to load the texture
      if (!textureManager.exists(`${username}-avatar`))
        loader.image(`${username}-avatar`, avatarURL_small || avatarURL);
    });

    loader.once(Phaser.Loader.Events.COMPLETE, () =>
      Object.keys(this.avatars).forEach((username) => {
        const avatar = this.avatars[username];
        avatar.setTexture(`${username}-avatar`);
        avatar.setDisplaySize(avatarSize, avatarSize);
      })
    );
    try {
      loader.start();
    } catch (err) {
      console.error(err);
    }
  }

  toggleReputationItems(visible) {
    // table headers
    this.headerReputation.setVisible(visible);
    this.headerTotalRewards.setVisible(visible);
    this.rewardsInfoButton.setVisible(visible);
    // player's info
    this.playerNetworth.setVisible(visible);
    this.playerStar.setVisible(visible);
    this.playerReward.setVisible(visible);
    this.tooltipRewards.setVisible(false);
  }

  toggleRaidPointsItems(visible) {
    // table headers
    this.headerRaidPts.setVisible(visible);
    this.headerTotalRewards.setVisible(visible);
    this.rewardsInfoButton.setVisible(visible);
    // player's info
    this.playerRaidPoints.setVisible(visible);
    this.playerReward.setVisible(visible);
    this.tooltipRewards.setVisible(false);
  }
  toggleUPointsItems(visible) {
    // table headers
    this.headerUPoints.setVisible(visible);
    this.headerXU.setVisible(visible);
    this.headerBlastGold.setVisible(visible);
    // player's info
    this.playerUPoints.setVisible(visible);
    this.playerXUReward.setVisible(visible);
    this.playerBlastGold.setVisible(visible);
    this.tooltipRewards.setVisible(false);
    // blast gold snapshot
    this.blastGoldSnapshot.setVisible(visible);
    this.blastGoldSnapshotClockIcon.setVisible(visible);
    this.blastGoldSnapshotTime.setVisible(visible);
  }

  reloadData() {
    if (!this.isSimulator) {
      this.showLoading();
      this.loading = true;
    }
    this.scene.game.events.emit(this.events.requestLeaderboardList, {
      pages: this.pages,
      limit: this.limit,
    });
  }

  changePage(newPage) {
    const type = this.modeSwitch.modeKey;

    if (this.loading) return;
    if (newPage === undefined || newPage === null) return;
    if (newPage < 0 || newPage > this.totalPages[type] + 1) return;
    if (this.pages[type] === newPage) return;

    this.pages[type] = newPage;
    this.updatePagination();
    this.reloadData();
  }

  updatePagination() {
    const pageBtns = [{ text: '1', page: 0 }];

    const currentPage = this.pages[this.modeSwitch.modeKey];
    const totalPages = this.totalPages[this.modeSwitch.modeKey];

    if (totalPages <= 5) {
      let count = 1;
      while (count < totalPages) {
        pageBtns.push({ text: `${count + 1}`, page: count });
        count++;
      }
    } else {
      if ([0, 1, totalPages - 2, totalPages - 1].includes(currentPage)) {
        pageBtns.push(
          ...[
            { text: '2', page: 1 },
            { text: '...' },
            { text: `${totalPages - 1}`, page: totalPages - 2 },
            { text: `${totalPages}`, page: totalPages - 1 },
          ]
        );
      } else {
        pageBtns.push(
          ...[
            { text: '...' },
            { text: `${currentPage + 1}`, page: currentPage },
            { text: '...' },
            { text: `${totalPages}`, page: totalPages - 1 },
          ]
        );
      }
    }

    // clear old items
    for (let mode in this.paginations) {
      const paginations = this.paginations[mode];
      paginations.map((item) => {
        this.remove(item);
        item.destroy();
      });
    }

    const canBack = currentPage > 0;
    const canNext = currentPage < totalPages - 1;

    const allPageBtns = [
      {
        text: '<',
        page: currentPage - 1,
        color: canBack ? '#C4CDD5' : '#f2f2f2',
        img: canBack ? 'pagination' : 'pagination-disabled',
      },
      ...pageBtns.map((item) => ({
        ...item,
        color: currentPage === item.page ? '#7C2828' : '#000000',
        img: currentPage === item.page ? 'pagination-active' : 'pagination',
      })),
      {
        text: '>',
        page: currentPage + 1,
        color: canNext ? '#C4CDD5' : '#f2f2f2',
        img: canNext ? 'pagination' : 'pagination-disabled',
      },
    ];

    const paginationY = this.leaderboardY + this.leaderboardContainer.height + 110;
    const paginationWidth = allPageBtns.length * paginationBtnSize + (allPageBtns.length - 1) * paginationBtnGap;
    this.paginations[this.modeSwitch.modeKey] = allPageBtns.map((item, index) => {
      const x =
        width / 2 - paginationWidth / 2 + index * (paginationBtnSize + paginationBtnGap) + paginationBtnSize / 2;
      const btn = new TextButton(
        this.scene,
        x,
        paginationY,
        item.img,
        item.img,
        () => this.changePage(item.page),
        item.text,
        {
          fontSize: '31px',
          color: item.color,
        }
      );
      this.add(btn);
      return btn;
    });
  }
}

class ModeSwitch extends Phaser.GameObjects.Container {
  mode = '';
  modeKey = '';

  constructor(scene, x, y, { containerImg = 'tabs-container', modeOne, modeTwo, modeThree } = {}) {
    super(scene, 0, 0);
    this.mode = modeOne.title;
    this.modeKey = modeOne.key;
    const textStyle = { fontSize: '48px', color: '#ffffff', fontFamily: fontFamilies.bold, align: 'center' };

    this.container = scene.add.image(x, y, containerImg).setOrigin(0.5, 0.5);
    this.add(this.container);

    const buttonOffset = this.container.width / 6;
    this.btnSingleOne = scene.add
      .image(x - buttonOffset, y, 'button-tab-single')
      .setOrigin(1, 0.5)
      .setAlpha(0);
    this.btnSingleTwo = scene.add
      .image(x + buttonOffset, y, 'button-tab-single')
      .setOrigin(0, 0.5)
      .setAlpha(0);
    this.btnDouble = scene.add.image(x + buttonOffset, y, 'button-tab-double');
    this.add(this.btnSingleOne);
    this.add(this.btnSingleTwo);
    this.add(this.btnDouble);

    this.textOne = scene.add
      .text(x - buttonOffset * 2, y, modeOne.title, textStyle)
      .setStroke('#0004a0', 10)
      .setOrigin(0.5, 0.5);
    this.textTwo = scene.add.text(x, y, modeTwo.title, textStyle).setStroke('#0004a0', 10).setOrigin(0.5, 0.5);
    this.textThree = scene.add
      .text(x + buttonOffset * 2, y, modeThree.title, textStyle)
      .setStroke('#0004a0', 10)
      .setOrigin(0.5, 0.5);
    this.add(this.textOne);
    this.add(this.textTwo);
    this.add(this.textThree);

    this.container
      .setInteractive()
      .on(Phaser.Input.Events.GAMEOBJECT_POINTER_DOWN, (pointer, localX, localY, event) => {
        const modeClicked = Math.ceil(localX / (this.container.width / 3));
        if (modeClicked === 2) {
          this.btnSingleOne.setAlpha(1);
          this.btnSingleTwo.setAlpha(1);
          this.btnDouble.setAlpha(0);
        } else {
          this.btnSingleOne.setAlpha(0);
          this.btnSingleTwo.setAlpha(0);
          this.btnDouble.setAlpha(1);

          this.btnDouble.x = modeClicked === 1 ? x + buttonOffset : x - buttonOffset;
        }

        const newMode = [modeOne, modeTwo, modeThree][modeClicked - 1];
        this.mode = newMode.title;
        this.modeKey = newMode.key;
        newMode.onClick();
      });
  }
}

export default PopupLeaderboard;

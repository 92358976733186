import Phaser from 'phaser';

import { fontFamilies } from '../../../../utils/styles';
import { formatter } from '../../../../utils/numbers';
import configs from '../../configs/configs';

const { width } = configs;

const formatTimeDigit = (digit) => (digit < 10 ? `0${digit}` : `${digit}`);

class AuctionCountdown extends Phaser.GameObjects.Container {
  interval = null;
  endTime = null;

  constructor(scene) {
    super(scene);
    this.scene = scene;

    const y = 460;
    this.text1 = scene.add
      .text(width / 2, y, 'Next auction in:', {
        fontSize: '60px',
        fontFamily: fontFamilies.extraBold,
        color: '#FFFFFF',
        align: 'center',
      })
      .setOrigin(0.5, 0.5);
    this.image = scene.add.image(width / 2, y, 'icon-clock').setOrigin(0.5, 0.5);
    this.text2 = scene.add
      .text(width / 2, y, '--:--:--', {
        fontSize: '72px',
        fontFamily: fontFamilies.extraBold,
        color: '#FFFFFF',
        align: 'center',
      })
      .setOrigin(0.5, 0.5);

    this.text1.x =
      width / 2 - (this.text1.width + 20 + this.image.width + 20 + this.text2.width) / 2 + this.text1.width / 2;
    this.image.x = this.text1.x + this.text1.width / 2 + 20 + this.image.width / 2;
    this.text2.x = this.image.x + this.image.width / 2 + 20 + this.text2.width / 2;

    this.add(this.text1);
    this.add(this.image);
    this.add(this.text2);

    this.nextPrizePool = scene.add
      .text(width / 2, y + 80, 'Next prize: - $GREED', {
        fontSize: '60px',
        fontFamily: fontFamilies.extraBold,
        color: '#FFFFFF',
        align: 'center',
      })
      .setOrigin(0.5, 0.5);
    this.add(this.nextPrizePool);

    this.getAuctionEndTime();
    this.getAuctionNextPrize();
  }

  getAuctionEndTime() {
    this.scene.game.events.emit('request-auction-end-time');
  }

  getAuctionNextPrize() {
    this.scene.game.events.emit('request-next-auction-prize');
  }

  loadData(endTime) {
    this.endTime = endTime;
    this.countdown();
  }

  loadNextPrizePool({ nextAuctionPrizePool }) {
    this.nextPrizePool.text = `Next prize: ${formatter.format(nextAuctionPrizePool)} $GREED`;
  }

  countdown() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }

    this.interval = setInterval(() => this.updateTime(), 1000);
  }

  updateTime() {
    if (!this.endTime) {
      clearInterval(this.interval);
      return;
    }

    const now = Date.now();
    const diffInSeconds = Math.max((this.endTime - now) / 1000, 0);
    const hours = Math.floor(diffInSeconds / 3600);
    const mins = Math.floor((diffInSeconds % 3600) / 60);
    const seconds = Math.round(diffInSeconds % 60);

    const h = formatTimeDigit(hours);
    const m = formatTimeDigit(mins);
    const s = formatTimeDigit(seconds);

    this.text2.text = `${h}:${m}:${s}`;
    this.text1.x =
      width / 2 - (this.text1.width + 20 + this.image.width + 20 + this.text2.width) / 2 + this.text1.width / 2;
    this.image.x = this.text1.x + this.text1.width / 2 + 20 + this.image.width / 2;
    this.text2.x = this.image.x + this.image.width / 2 + 20 + this.text2.width / 2;
  }
}

export default AuctionCountdown;
